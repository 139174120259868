<template>
  <Container space-before space-after>
    <div :style="panelStyle" :class="['panels']">
      <slot />
    </div>
  </Container>
</template>

<script>
  import Container from "~/components/Container"

  export default {
    components: { Container },
    props: {
      itemCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      panelStyle() {
        if (this.itemCount <= 2) {
          return {
            gridTemplateColumns: `repeat(${this.itemCount}, minmax(auto, 405px))`,
          }
        }
        return null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .panels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $gutter;
    align-content: center;
    justify-content: center;

    @include viewport("sm") {
      grid-gap: $base-spacing * 6;
      grid-template-columns: repeat(2, 1fr);
      flex-direction: column;
    }
    @include viewport("mini") {
      grid-gap: $base-spacing * 6;
      grid-template-columns: 1fr;
    }
  }
</style>
