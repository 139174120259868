<template>
  <PanelRow :item-count="nestedElements.length">
    <AlchemyPanel
      v-for="panel in nestedElements"
      :key="panel.id"
      :element="panel"
    />
  </PanelRow>
</template>

<script>
  import AlchemyPanel from "./panel"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: { AlchemyPanel },
    mixins: [AlchemyElement],
    computed: {
      nestedElements() {
        return this.element.nestedElements
      },
    },
  }
</script>
