<template>
  <div class="panel">
    <NuxtLink
      :class="['panel-link', darkText && 'panel-text-color-black']"
      :to="link"
    >
      <Headline
        v-if="title"
        :level="3"
        alignment="center"
        class="panel-headline"
      >
        {{ title }}
      </Headline>
      <picture>
        <source
          v-for="imgTypes in createFormatArray(srcset)"
          :key="imgTypes.type"
          :type="imgTypes.type"
          :srcset="imgTypes.srcsetstring"
          sizes="(min-width: 1344px) 405px,(min-width: 960px) calc(33vw - 128px),
           (min-width: 660px) calc(50vw - 56px), calc(100vw - 32px)"
        />

        <img
          role="none"
          :src="image"
          loading="lazy"
          class="panel-image"
          :alt="altText"
        />
      </picture>
    </NuxtLink>
    <p class="panel-description">{{ description }}</p>
  </div>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [createFormatArray],
    props: {
      image: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: "",
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      altText: {
        type: String,
        default: "",
      },
      link: {
        type: String,
        default: "",
      },
      darkText: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        srcsetString: this.srcset
          ? this.srcset.map((e) => `${e.url} ${e.desc}`)
          : null,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .panel {
    width: 100%;
  }

  .panel-description {
    margin: $space-s 0 0 0;
    color: $gray-text;
    text-align: center;
    .cs-dark & {
      color: $gray-200;
    }
  }

  .panel-link {
    overflow: hidden;
    width: 100%;
    padding: 15%;
    box-sizing: border-box;
    aspect-ratio: 1/1;
    position: relative;
    text-decoration: none;
    color: white;
    border-radius: $border-radius;
    display: block;
    box-shadow: $shadow;
    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    will-change: transform;
    backface-visibility: hidden;
    &:hover,
    &:focus {
      box-shadow: $hover-shadow;
      transform: scale(1.02);
    }
    &.panel-text-color-black {
      color: black;
      &:before {
        background: white;
      }
    }
    &:before {
      background: black;
      opacity: $overlayColorOpacity;
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .panel-headline {
    padding: $base-spacing * 2;
    position: relative;
    z-index: 5;
  }

  .panel-image {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
  }
</style>
