<template>
  <Panel
    :title="title"
    :image="image"
    :link="link"
    :srcset="srcset"
    :dark-text="darkText"
    :description="description"
    :alt-text="altText"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],

    computed: {
      title() {
        return this.getValue("title")
      },
      image() {
        return this.srcset ? this.srcset[this.srcset.length - 1].url : null
      },
      srcset() {
        return this.getIngredient("image")?.srcset
      },
      altText() {
        return this.getIngredient("image")?.altText
      },
      link() {
        return this.getValue("link")
      },
      darkText() {
        return this.getValue("dark_text")
      },
      description() {
        return this.getValue("description")
      },
    },
  }
</script>
